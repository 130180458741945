/* Container for the chat window */
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Chat message container */
.message-container {
  height: 400px;
  overflow-y: auto;
  padding: 10px;
}

/* Individual chat message */
.message {
  display: inline-block;
  max-width: 80%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.user {
  background-color: #f2f2f2;
  color: #333;
  float: left;
}

.bot {
  background-color: #A230ED;
  color: #fff;
  float: right;
}

/* Input field for user messages */
.user-input {
  width: 100%;
  padding: 10px;
  border: none;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;
}

.user-input:focus {
  outline: none;
}

.open-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
}

.open-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.ai-bot-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.message-sender{
  font-weight: bold;
}

.input-row {
  display: flex;
  align-items: center;
}


.send-button {
  flex-shrink: 0;
  margin-top: 10px;
  margin-right: 10px;
  background-color: #A230ED;
  color: #ffffff;
  padding: 5px 5px;
  border-radius: 4px;
  cursor: pointer;
}
